<template>
	<div class="w-full">
		<div
			v-for="(item, index) in geoSections"
			:key="item.key"
			class="flex w-full px-3.5 py-2 mx-0 text-left hover:bg-gray-200 hover:shadow-inner"
			:class="{[HIGHLIGHTED_CLASS]: index === highlightedIndex}"
			@click="$router.push(item?.url)"
		>
			<NavIcon
				:icon="item.name"
				class="mr-2"
				icon-classes="ml-1.5"
			/> {{ item.name }}
		</div>
	</div>
</template>

<script async>
import { mapActions, mapGetters } from 'vuex'

import NavIcon from '@/components/navigation/NavIcon.vue'
import { HIGHLIGHTED_CLASS } from '@/constants/search/index.js'

export default {
	components: {
		NavIcon
	},
	data() {
		return {
			HIGHLIGHTED_CLASS
		}
	},
	computed: {
		...mapGetters([
			'geoSections',
			'locationUrl'
		]),
		...mapGetters('search', [ 'highlightedIndex', 'highlightedRoute' ]),
		emptyListCount() {
			return this.geoSections?.length || 0
		}
	},
	mounted() {
		this.setIndexCount(this.emptyListCount)
	},
	methods: {
		...mapActions('search', [ 'setIndexCount', 'setHighlightedRoute' ])
	}
}
</script>
